<template>
  <div class="mb-50">
    <!-- ANCHOR - Filter Booking -->
    <b-row>
      <!-- ANCHOR book date -->
      <b-col
        cols="12"
        md="4"
        lg="2"
      >
        <b-form-group class="w-100 mb-0">
          <!-- <label class="font-weight-bold">{{ $t('reservation.bookDate') }}</label> -->
          <b-input-group
            id="range-date"
            class=""
          >
            <flat-pickr
              v-model="rangeDate"
              class="form-control form-control-md"
              :config="{
                mode: 'range',
                dateFormat: 'Y-m-d',
                altFormat: 'd-m-Y',
                class: 'form-control-md',
                altInput: true,
                locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
                disableMobile: true,
              }"
              :placeholder="$t('reservation.bookDate')"
            />

            <b-input-group-append is-text>
              <feather-icon icon="CalendarIcon" />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <!-- ANCHOR flight date -->
      <b-col
        cols="12"
        md="4"
        lg="2"
        class="mt-75 mt-md-0"
      >
        <b-form-group class="w-100 mb-0">
          <!-- <label class="font-weight-bold">{{ $t('reservation.flightDate') }}</label> -->
          <b-input-group
            id="range-date"
            class=""
          >
            <flat-pickr
              v-model="rangeDateFlights"
              class="form-control form-control-md"
              :config="{
                mode: 'range',
                dateFormat: 'Y-m-d',
                altFormat: 'd-m-Y',
                altInput: true,
                class: 'form-control-md',
                locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
                disableMobile: true,
              }"
              :placeholder="$t('reservation.flightDate')"
            />

            <b-input-group-append is-text>
              <feather-icon icon="CalendarIcon" />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <!-- ANCHOR: Status -->
      <!-- <label class="font-weight-bold mb-0 text-nowrap text-truncate">{{ $t('reservation.status') }}</label> -->
      <b-col
        cols="12"
        md="4"
        lg="2"
        class="no_wrap_input mt-75 mt-md-0"
      >
        <b-form-group class="w-100 mb-0">
          <b-input-group class="input-group-merge">
            <vue-autosuggest
              v-model="selectedStatus"
              :suggestions="filteredStatus"
              :limit="20"
              :input-props="{
                id: 'autosuggest__input',
                class: 'form-control form-control-md',
                style: 'border-radius: 0.357rem 0px 0px 0.357rem',
                placeholder: $t('reservation.status')
              }"
              class="flex-grow-1"
              :get-suggestion-value="getSuggestionValue"
              @input="onInputChangeStatus"
              @selected="onOptionSelectStatus"
            >
              <template slot-scope="{ suggestion }">
                <span class="my-suggestion-item">
                  {{ suggestion.item.value ? vn_status[suggestion.item.value] : vn_status.ALL }}
                </span>
              </template>
            </vue-autosuggest>
            <b-input-group-append is-text>
              <feather-icon icon="ChevronDownIcon" />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <!-- ANCHOR created by -->
      <b-col
        cols="12"
        md="4"
        lg="2"
        class="mt-75 mt-lg-0"
      >
        <b-form-group
          label-for="created-by-select"
          class="w-100 mb-0"
        >
          <!-- <label class="font-weight-bold">{{ $t('createdBy') }}</label> -->
          <v-select
            id="created-by-select"
            :options="createdByOptions"
            label="firstName"
            searchable
            :filterable="false"
            :value="createdByFilter"
            :loading="isLoadingCreatedBy"
            :placeholder="$t('reservation.filters.createdBy')"
            :reduce="val => val"
            append-to-body
            :calculate-position="withPopper"
            @open="handleOpenCreatedBy"
            @search="handleSearchCreatedBy"
            @input="(val) => $emit('update:createdByFilter', val)"
          >
            <template #search="{ attributes, events }">
              <input
                class="vs__search"
                :class="{
                  'text-uppercase': attributes.value
                }"
                v-bind="attributes"
                v-on="events"
              >
            </template>
            <template #selected-option="{ firstName, lastName, username }">
              <div class="w-100 d-flex-between text-truncate">
                <span class="font-weight-bold d-block text-nowrap">
                  {{ lastName }} {{ firstName }} ({{ username }})
                </span>
              </div>
            </template>
            <template #spinner="{ loading }">
              <div
                v-if="loading"
                style="border-left-color: rgba(88, 151, 251, 0.71)"
                class="vs__spinner"
              />
            </template>
            <template #option="{ firstName, lastName, username }">
              <b-row>
                <b-col
                  cols="12"
                  md="9"
                >
                  <span class="font-weight-bold d-block text-nowrap">
                    {{ lastName }} {{ firstName }} ({{ username }})
                  </span>
                </b-col>
              </b-row>
            </template>
            <template #no-options>
              {{ $t('noOptions') }}
            </template>
          </v-select>
        </b-form-group>
      </b-col>

      <!-- ANCHOR agency code -->
      <b-col
        cols="12"
        md="4"
        lg="2"
        class="mt-75 mt-lg-0"
      >
        <b-form-group
          label-for="agency-code"
          class="w-100 mb-0"
        >
          <!-- <label class="font-weight-bold">{{ $t('createdBy') }}</label> -->
          <v-select
            id="agency-code"
            :options="agencyCodeOptions"
            label="agencyCode"
            searchable
            :filterable="false"
            :value="agencyCodeFilter"
            :loading="isLoadingAgencyCode"
            :placeholder="$t('reservation.filters.agencyCode')"
            :reduce="val => val"
            append-to-body
            :calculate-position="withPopper"
            @open="handleOpenAgencyCode"
            @search="handleSearchAgencyCode"
            @input="(val) => $emit('update:agencyCodeFilter', val)"
          >
            <template #search="{ attributes, events }">
              <input
                class="vs__search"
                :class="{
                  'text-uppercase': attributes.value
                }"
                v-bind="attributes"
                v-on="events"
              >
            </template>

            <template #selected-option="data">
              <div class="w-100 d-flex-between text-truncate">
                <span class="font-weight-bold d-block text-nowrap">
                  <span class="text-uppercase">{{ data.agencyCode }}</span> ({{ data.agencyName }})
                </span>
              </div>
            </template>
            <template #option="data">
              <b-row>
                <b-col
                  cols="12"
                  md="9"
                >
                  <span class="font-weight-bold d-block text-nowrap">
                    <span class="text-uppercase">{{ data.agencyCode }}</span> ({{ data.agencyName }})
                  </span>
                </b-col>
              </b-row>
            </template>
            <template #spinner="{ loading }">
              <div
                v-if="loading"
                style="border-left-color: rgba(88, 151, 251, 0.71)"
                class="vs__spinner"
              />
            </template>
            <template #no-options>
              {{ $t('noOptions') }}
            </template>
          </v-select>
        </b-form-group>
      </b-col>

      <!-- ANCHOR isActive : show hide booking -->
      <b-col
        cols="12"
        md="4"
        lg="2"
        class=""
      >
        <b-form-group
          label-for="show-hide-booking"
          class="w-100 mb-0 mt-75 mt-lg-0"
        >
          <v-select
            id="show-hide-booking"
            :value="isActiveFilter"
            :options="isActiveOptions"
            label="label"
            :reduce="item => item.value"
            :placeholder="$t('reservation.showHideBooking')"
            @input="(val) => $emit('update:isActiveFilter', val)"
          >
            <template #option="{ label }">
              <div class="w-100 d-flex-between text-truncate">
                <span class="font-weight-bold d-block text-nowrap">
                  {{ $te(`reservation.${label}`) ? $t(`reservation.${label}`) : label }}
                </span>
              </div>
            </template>
            <template #selected-option="{ label }">
              <div class="w-100 d-flex-between text-truncate">
                <span class="font-weight-bold d-block text-nowrap">
                  {{ $te(`reservation.${label}`) ? $t(`reservation.${label}`) : label }}
                </span>
              </div>
            </template>
            <template #spinner="{ loading }">
              <div
                v-if="loading"
                style="border-left-color: rgba(88, 151, 251, 0.71)"
                class="vs__spinner"
              />
            </template>
          </v-select>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- ANCHOR - Reset and SEARCH -->
    <b-row class="d-flex-center">
      <!-- ANCHOR - Search PNR -->
      <b-col
        cols="12"
        md="6"
        lg="auto"
        class="flex-grow-1 mt-75 pr-md-50 pr-lg-1"
        :class="isMobileView ? 'flex-column' : 'd-flex-center gap-3'"
      >
        <!-- ANCHOR searchText: tạm ẩn với F1 F2 -->
        <b-input-group class="input-group-merge mb-75 mb-md-0">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            :value="searchTextFilter"
            type="search"
            maxlength="255"
            debounce="100"
            :disabled="!canAccess('booking.searchBooking')"
            :formatter="removeAccentsUpperCaseFormatterNotTrim"
            :placeholder="$t('reservation.placeHolderSearchText')"
            @input="(value) => handleDebounceSearchText(value)"
          />
        </b-input-group>

        <!-- ANCHOR bookingCode -->
        <b-input-group
          v-if="!isRoleF3"
          class="input-group-merge"
        >
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            id="input-booking-code"
            :value="bookingCodeFilter"
            type="search"
            maxlength="6"
            minlength="6"
            debounce="100"
            :disabled="!canAccess('booking.searchBooking')"
            :formatter="removeAccentsUpperCaseFormatter"
            :placeholder="$t('reservation.placeHolderFindPNR')"
            @input="(value) => handleDebounceBookingCode(value)"
          />
          <b-tooltip
            v-if="appBreakPoint !== 'xs'"
            id="tooltip-booking-code"
            target="input-booking-code"
            triggers="hover focus"
            placement="top"
            boundary="viewport"
            variant="info"
            style="width: max-content;"
          >
            <div
              class="text-white"
              style="font-size: 15px"
            >
              Nhập chính xác <span class="font-weight-bolder">Mã đặt chỗ</span> (Gồm 6 ký tự) để tìm kiếm
            </div>
          </b-tooltip>
        </b-input-group>
      </b-col>

      <!-- ANCHOR: Airline -->
      <b-col
        cols="12"
        md="3"
        lg="2"
        class="no_wrap_input mt-75 order-first order-md-0"
      >
        <b-form-group class="w-100 mb-0">
          <!-- <label class="font-weight-bold mb-0 text-nowrap text-truncate">{{ $t('reservation.airline') }}</label> -->
          <b-input-group class="input-group-merge">
            <vue-autosuggest
              v-model="selectedAirline"
              :suggestions="filteredOptions"
              :limit="5"
              :input-props="{
                id: 'autosuggest__input',
                class: 'form-control form-control-md',
                style: 'border-radius: 0.357rem 0px 0px 0.357rem',
                placeholder: $t('reservation.airline')
              }"
              class="flex-grow-1"
              :get-suggestion-value="getSuggestionValue"
              @input="onInputChange"
              @selected="onOptionSelect"
            >
              <template slot-scope="{ suggestion }">
                <span class="my-suggestion-item">
                  {{ suggestion.item.value }} ({{ suggestion.item.key }})
                </span>
              </template>
            </vue-autosuggest>
            <b-input-group-append
              is-text
              class="border-0"
            >
              <feather-icon icon="ChevronDownIcon" />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <!-- ANCHOR: Source -->
      <b-col
        cols="12"
        md="3"
        lg="2"
        class="no_wrap_input mt-75 order-first order-md-0"
      >
        <b-form-group class="w-100 mb-0">
          <v-select
            id="agency-code"
            :options="sourceOptions"
            label="value"
            searchable
            :filterable="false"
            :value="sourceFilter"
            :placeholder="$t('flight.source')"
            :reduce="val => val.key"
            append-to-body
            :calculate-position="withPopper"
            @input="(val) => $emit('update:sourceFilter', val)"
          >
            <template #selected-option="data">
              <div class="w-100 d-flex-between text-truncate">
                <span class="font-weight-bold d-block text-nowrap">
                  {{ data.value }}
                </span>
              </div>
            </template>
            <template #option="data">
              <b-row>
                <b-col
                  cols="12"
                  md="9"
                  class="px-0"
                >
                  <span class="font-weight-bold d-block text-nowrap">
                    {{ data.value }}
                  </span>
                </b-col>
              </b-row>
            </template>
            <template #spinner="{ loading }">
              <div
                v-if="loading"
                style="border-left-color: rgba(88, 151, 251, 0.71)"
                class="vs__spinner"
              />
            </template>
            <template #no-options>
              {{ $t('noOptions') }}
            </template>
          </v-select>
        </b-form-group>
      </b-col>

      <!-- ANCHOR - BUTTONs -->
      <b-col
        cols="12"
        lg="auto"
        class="mt-75 d-flex-center flex-row-reverse"
      >
        <b-button
          v-if="isExport"
          class="font-weight-bolder text-primary d-flex-center gap-1"
          variant="outline-primary"
          @click="openModalFilterToExport"
        >
          <feather-icon
            v-if="!isMobileView"
            icon="DownloadIcon"
          />
          <span>{{ $t('export') }}</span>
        </b-button>

        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          class="font-weight-bolder mx-1"
          variant="outline-danger"
          :disabled="emptyFilter"
          @click="$emit('reset'), resetFilter()"
        >
          <feather-icon
            v-if="!isMobileView"
            icon="RefreshCwIcon"
            class="mr-50 text-danger"
          />
          <span
            class="align-middle text-danger font-weight-bolder"
            style=""
          >{{ $t('clear') }}
          </span>
        </b-button>

        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          class="font-weight-bolder"
          variant="outline-warning"
          :disabled="emptyFilter || !canAccess('booking.searchBooking')"
          @click="$emit('fetch-data')"
        >
          <feather-icon
            v-if="!isMobileView"
            icon="SearchIcon"
            class="mr-50 text-warning"
          />
          <span
            class="align-middle text-warning font-weight-bolder"
            style=""
          >{{ $t('search') }}
          </span>
        </b-button>
      </b-col>
    </b-row>

    <FilterToExport
      :brand-options="brandOptions"
      :status-options="statusOptions"
    />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BTooltip,
} from 'bootstrap-vue'
import { VueAutosuggest } from 'vue-autosuggest'
import flatPickr from 'vue-flatpickr-component'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import debounce from 'lodash/debounce'
import { ref, computed } from '@vue/composition-api'
import vSelect from 'vue-select'
import { createPopper } from '@popperjs/core'

import store from '@/store'
import api from '@/api'
import { isActiveOptions } from '@/constants/selectOptions'

import { removeAccentsUpperCaseFormatter, removeAccentsUpperCaseFormatterNotTrim } from '@core/comp-functions/forms/formatter-input'

import FilterToExport from './FilterToExportModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BTooltip,
    VueAutosuggest,
    flatPickr,
    vSelect,
    FilterToExport,
  },
  props: {
    brandFilter: {
      type: [String, null],
      default: null,
    },
    sourceFilter: {
      type: [String, null],
      default: null,
    },
    statusFilterByUser: {
      type: [String, null],
      default: null,
    },
    emptyFilter: {
      type: Boolean,
      default: null,
    },
    isActiveFilter: {
      type: [Boolean, String, null],
      default: null,
    },
    startDateFilter: {
      type: String,
      default: null,
    },
    endDateFilter: {
      type: String,
      default: null,
    },
    brandOptions: {
      type: Array,
      default: () => [],
    },
    sourceOptions: {
      type: Array,
      default: () => [],
    },
    statusOptions: {
      type: Array,
      default: () => [],
    },
    createdByFilter: {
      type: [Object, null],
      default: () => { },
    },
    agencyCodeFilter: {
      type: [Object, null],
      default: () => { },
    },
    searchTextFilter: {
      type: String,
      default: '',
    },
    bookingCodeFilter: {
      type: String,
      default: '',
    },
    isExport: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      filteredOptions: [],
      filteredSourceOptions: [],
      filteredStatus: [],
      rangeDate: [],
      rangeDateFlights: [],

      searchText: '',
      selectedStatus: null,
      selectedAirline: null,
      selectedSource: null,

      vn_status: {
        ALL: 'Tất cả (mặc định)',
        HOLD: 'Giữ chỗ',
        PAID: 'Đã thanh toán',
        PARTIALLY_PAID: 'Còn phí treo',
        CANCEL: 'Đã huỷ',
        EXPIRED: 'Quá hạn',
        DRAFT: 'Bản nháp',
      },
    }
  },

  watch: {
    brandOptions(value) {
      this.filteredOptions = [
        {
          data: [...value],
        },
      ]
    },

    sourceOptions(value) {
      this.filteredSourceOptions = [
        {
          data: [...value],
        },
      ]
    },

    statusOptions(value) {
      this.filteredStatus = [
        {
          data: [...value],
        },
      ]
    },

    rangeDate(value) {
      if (!value) return
      const sepSign = this.$i18n.locale === 'vi' ? ' đến ' : ' to '
      const dateRange = value.split(sepSign)
      if (dateRange.length > 1) {
        this.$emit('update:startDateFilter', dateRange[0])
        this.$emit('update:endDateFilter', dateRange[1])
      } else if (dateRange.length === 1) {
        this.$emit('update:startDateFilter', dateRange[0])
        this.$emit('update:endDateFilter', dateRange[0])
      }
    },

    rangeDateFlights(value) {
      if (!value) return
      const sepSign = this.$i18n.locale === 'vi' ? ' đến ' : ' to '
      const dateRange = value.split(sepSign)
      if (dateRange.length > 1) {
        this.$emit('update:startDateFlightFilter', dateRange[0])
        this.$emit('update:endDateFlightFilter', dateRange[1])
      } else if (dateRange.length === 1) {
        this.$emit('update:startDateFlightFilter', dateRange[0])
        this.$emit('update:endDateFlightFilter', dateRange[0])
      }
    },
  },

  created() {
    this.filteredOptions = [
      {
        data: [...this.brandOptions],
      },
    ]

    this.filteredSourceOptions = [
      {
        data: [...this.sourceOptions],
      },
    ]

    this.filteredStatus = [
      {
        data: [...this.statusOptions],
      },
    ]
  },

  methods: {
    onOptionSelect(suggestion) {
      this.$emit('update:brandFilter', suggestion.item.key)
      this.selectedAirline = suggestion.item.value
    },

    onOptionSourceSelect(suggestion) {
      this.$emit('update:sourceFilter', suggestion.item.key)
      this.selectedSource = suggestion.item.value
    },

    onOptionSelectStatus(suggestion) {
      this.$emit('update:statusFilterByUser', suggestion.item.value)
      this.selectedStatus = suggestion.item.key !== 'All' ? this.vn_status[suggestion.item.value] : this.vn_status.ALL
    },

    getSuggestionValue(suggestion) {
      return suggestion.item.value
    },

    onInputChange(text) {
      if (text === '' || text === undefined) {
        return
      }

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredDevelopers = this.brandOptions
        .filter(item => item.key.toLowerCase() === text.toLowerCase() || item.value.toLowerCase().indexOf(text.toLowerCase()) > -1)
        .slice(0, this.limit)
      const filteredData = filteredDevelopers
      this.filteredOptions = [
        {
          data: filteredData,
        },
      ]

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredSourcez = this.sourceOptions
        .filter(item => item.key.toLowerCase() === text.toLowerCase() || item.value.toLowerCase().indexOf(text.toLowerCase()) > -1)
        .slice(0, this.limit)
      const filteredSourceData = filteredSourcez

      this.filteredSourceOptions = [
        {
          data: filteredSourceData,
        },
      ]
    },

    onInputChangeStatus(text) {
      if (text === '' || text === undefined) {
        return
      }

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredDevelopers = this.statusOptions
        .filter(item => item.value.toLowerCase().indexOf(text.toLowerCase()) > -1)
        .slice(0, this.limit)
      const filteredData = filteredDevelopers
      this.filteredStatus = [
        {
          data: filteredData,
        },
      ]
    },

    resetFilter() {
      this.rangeDate = null
      this.rangeDateFlights = null

      // this.filteredOptions = []
      // this.filteredStatus = []
      this.$emit('update:brandFilter', null)
      this.$emit('update:sourceFilter', null)
      this.$emit('update:statusFilterByUser', null)

      this.selectedStatus = null
      this.selectedAirline = null
      this.selectedSource = null

      // this.onOptionSelectStatus({ item: { value: null } })
    },

    withPopper(dropdownList, component, { width }) {
      const dropdownWidth = `calc(${Number(width.split('px')[0]) + 100}px )` || width // + 25px
      dropdownList.style.width = dropdownWidth
      dropdownList.style.translate = `calc((${dropdownWidth} - ${this.right ? '0' : width})/2${this.right ? '*(-1)' : ''})`
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'bottom',
      })
      return () => popper.destroy()
    },
  },
  setup(_, { emit }) {
    const handleDebounceSearchText = debounce(searchText => {
      emit('updateSearchTextFilter', searchText)
    }, 300)

    const handleDebounceBookingCode = debounce(bookingCode => {
      if (![0, 6].includes(bookingCode.length)) return
      emit('updateBookingCodeFilter', bookingCode)
    }, 300)

    function openModalFilterToExport() {
      this.$bvModal.show('modal-reservation-booking-export-excel')
    }

    const createdByOptions = ref([])
    const agencyCodeOptions = ref([])
    const isLoadingCreatedBy = ref(false)
    const isLoadingAgencyCode = ref(false)
    const createdByOptionsDefault = ref([])
    const agencyCodeOptionsDefault = ref([])

    const fetchEmployeesByFilterSearch = async (search = '') => {
      createdByOptions.value = []
      isLoadingCreatedBy.value = true
      try {
        const data = await api.employee.fetchEmployeesByFilter({ searchText: search })
        if (data && !search) {
          createdByOptionsDefault.value = data.items
        }
        createdByOptions.value = data?.items || []
      } catch (error) {
        console.error(error)
      } finally {
        isLoadingCreatedBy.value = false
      }
    }

    function handleOpenCreatedBy() {
      if (createdByOptionsDefault.value.length === 0) {
        fetchEmployeesByFilterSearch()
      } else {
        createdByOptions.value = createdByOptionsDefault.value
      }
    }

    const handleSearchCreatedBy = debounce(search => {
      if (search) fetchEmployeesByFilterSearch(search)
    }, 300)

    const fetchAgenciesByFilterSearch = async (search = '') => {
      agencyCodeOptions.value = []
      isLoadingAgencyCode.value = true
      try {
        const { data } = await api.agencies.fetchAgencies({ searchText: search })
        if (data && !search) {
          agencyCodeOptionsDefault.value = data.items
        }
        agencyCodeOptions.value = data?.items || []
      } catch (error) {
        console.error(error)
      } finally {
        isLoadingAgencyCode.value = false
      }
    }
    function handleOpenAgencyCode() {
      if (agencyCodeOptionsDefault.value.length === 0) {
        fetchAgenciesByFilterSearch()
      } else {
        agencyCodeOptions.value = agencyCodeOptionsDefault.value
      }
    }

    const handleSearchAgencyCode = debounce(search => {
      if (search) fetchAgenciesByFilterSearch(search)
    }, 300)
    const isRoleF3 = computed(() => store.getters['userStore/getRoleCTV'])
    return {
      Vietnamese,
      handleDebounceSearchText,
      handleDebounceBookingCode,
      openModalFilterToExport,
      handleSearchCreatedBy,
      handleOpenCreatedBy,
      createdByOptions,
      isLoadingCreatedBy,
      isLoadingAgencyCode,
      removeAccentsUpperCaseFormatter,
      isRoleF3,
      isActiveOptions,

      agencyCodeOptions,
      handleOpenAgencyCode,
      handleSearchAgencyCode,
      removeAccentsUpperCaseFormatterNotTrim,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
#range-date ::v-deep {
  input.form-control.input {
    border-radius: 0.357rem 0 0 0.357rem;
    border-right: none;
  }
}

.no_wrap_input .input-group::v-deep {
  flex-wrap: nowrap !important;

  .autosuggest__results {
    min-width: 166px; // min-xl-wrap
  }
}

#tooltip-booking-code ::v-deep {
  .tooltip-inner {
    max-width: fit-content;
  }
}
</style>
