<template>
  <b-card
    header-class="pt-50 py-0"
    class="border mt-1"
    body-class="py-75"
  >
    <template #header>
      <h4 class="m-0">
        Chọn hình thức thanh toán
      </h4>
    </template>

    <div>
      <b-card-group
        deck
        class="mt-1"
      >
        <b-card
          class="border"
          :class="!isPay ? 'border-info' : ''"
          no-body
          @click="handleSelectOption(isPay, 'hold')"
        >
          <b-card-body class="p-50">
            <div class="text-right">
              <feather-icon
                v-if="!isPay"
                :size="isMobileView ? '20' : '32'"
                icon="CheckCircleIcon"
                class="text-info"
              />
              <feather-icon
                v-else
                :size="isMobileView ? '20' : '32'"
                icon="CircleIcon"
                class="text-secondary"
              />
            </div>
            <div class="d-flex-column mt-1">
              <span
                class="font-weight-bolder text-nowrap text-warning"
                :class="`${isMobileView ? 'font-small-4': 'font-medium-4'} ${!isPay ? 'text-success' : ''}`"
              >
                Thanh toán sau
              </span>
              <div class="font-italic mt-50 text-body">
                Phí dịch vụ sẽ được treo, cần phải  thanh toán phí dịch vụ thêm một bước nữa.
              </div>
            </div>
          </b-card-body>
        </b-card>

        <b-card
          class="border"
          :class="isPay ? 'border-info' : ''"
          no-body
          @click="handleSelectOption(isPay, 'pay', reservationsData)"
        >
          <b-card-body class="p-50">
            <div class="text-right">
              <feather-icon
                v-if="isPay"
                :size="isMobileView ? '20' : '32'"
                icon="CheckCircleIcon"
                class="text-info"
              />
              <feather-icon
                v-else
                :size="isMobileView ? '20' : '32'"
                icon="CircleIcon"
                class="text-secondary"
              />
            </div>
            <div class="d-flex-column mt-1">
              <span
                class="font-weight-bolder text-nowrap  text-success"
                :class="`${isMobileView ? 'font-small-4': 'font-medium-4'} ${isPay ? 'text-success' : ''}`"
              >
                Thanh toán ngay
              </span>
              <div class="font-italic mt-50 text-body">
                Phí dịch vụ sẽ được thanh toán ngay sau khi dịch vụ được thêm thành công vào mã đặt chỗ.
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-card-group>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardGroup,
  BCardBody,
} from 'bootstrap-vue'
import { toRefs } from '@vue/composition-api'

// import env from '@/libs/env'

import useToast from '@useToast'

export default {
  components: {
    BCard,
    BCardGroup,
    BCardBody,
  },
  props: {
    reservationsData: {
      type: Object,
      default: () => {},
    },
    isPayNow: {
      type: Boolean,
      default: () => false,
    },
    hasPaidTrip: { // cho source ['TR', 'JQ'] // test dev cho VN1A
      type: Boolean,
      default: () => false,
    },
    place: {
      type: String,
      default: () => '',
    },
  },
  setup(props, { emit }) {
    const { toastError } = useToast()

    const isPay = toRefs(props).isPayNow

    // const { isDevelopment } = env

    const { hasPaidTrip, place } = toRefs(props)

    function handleSelectOption(data, type, bookingData) {
      if (type === 'hold' && data) {
        emit('update:is-pay-now', false)
      }

      if (type === 'pay' && !data) {
        if (['VJ'].includes(bookingData.source)) {
          if (bookingData.totalAmountToBePaid <= 0) {
            emit('update:is-pay-now', true)
          } else {
            toastError({
              title: 'Mã đặt chỗ còn phí treo, không thể thực hiện thanh toán ngay!',
            })
          }
        }

        // if (['TR', 'JQ'].includes(bookingData.source) || (isDevelopment && ['VN1A', 'VN1A_MT'].includes(props.reservationsData?.source))) {
        if (['TR', 'JQ'].includes(bookingData.source)) {
          if (hasPaidTrip.value) {
            emit('update:is-pay-now', true)
          } else {
            toastError({ title: 'Mã đặt chỗ chưa thanh toán!' })
          }
        }

        // if (['1S', '1S_CTRL'].includes(bookingData.source)) {
        //   if (hasPaidTrip.value) {
        //     emit('update:is-pay-now', true)
        //   } else {
        //     toastError({
        //       title: place.value.includes('1S') ? 'Có hành khách chưa có số vé trên chặng hiện tại, không thể thực hiện thanh toán ngay!' : 'Hành trình đang thêm dịch vụ chưa thanh toán, vui lòng thanh toán hành trình để sử dụng chức năng!',
        //     })
        //   }
        // }
      }
    }

    return {
      isPay,
      handleSelectOption,
    }
  },
}
</script>
